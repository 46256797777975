/* TailwindCss */
@tailwind base;
@tailwind components;
@tailwind utilities;
.z-9999 {
    z-index:9999;
}

    table td {
      padding-left: 0.75rem  !important;
      padding-right: 0.75rem  !important;
    }
